export const Endpoints = {
  login: "/users/login",
  feedback: "/feedback",
  register: "/users/register",
  update: "/users/update",
  updatePassword: "/users/update/password",
  verify: "/users/verify",
  verifyToken: "/users/verify/token",
  me: "/users/me",
  purgeData: "/users/purge-data",
  chats: "/chats",
  chat: "/chats/show/",
  chatUnlock: "/chats/unlock/",
  chatSecret: "/chats/secret/",
  receivedChatRequest: "/chats/requests",
  acceptRequest: "/chats/requests/accept/",
  chatMessages: "/chats/messages/",
  sendMessage: "/chats/messages/",
  sendEmail: "/emails",
  encryptText: "/documents",
  updateEncrypText: "/documents/",
  deleteEncrypText: "/documents/delete/",
  decryptText: "/documents/decrypt/",
  email: "/emails",
  deleteEmail: "/emails/delete/",
};
